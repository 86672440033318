<template>
  <div class="viewDashBoard project-route-index" v-cloak>
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else class="white-row">
        <div class="show-project-portal-listing">
          <!-- Create Project Form  -->
          <div class="form-area">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row top-gen-heading">
                    <h1 class="global-style-heading">
                      Add Employee
                    </h1>
                  </div>
                </div>
                <div class="col-sm-12">
                  <form
                    class="emp-create-form"
                    v-on:submit.prevent="submit($event)"
                  >
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="name"
                              >Employee Name: <span>*</span></label
                            >
                            <input
                              v-model="formData.name"
                              type="text"
                              class="form-control"
                              placeholder="Employee Name"
                              :class="{
                                'input-border-errors':
                                  $v.formData.name.$dirty &&
                                  !$v.formData.name.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="email"
                              >Employee Email: <span>*</span></label
                            >
                            <input
                              v-model="formData.email"
                              type="email"
                              class="form-control"
                              placeholder="Employee Email"
                              :class="{
                                'input-border-errors':
                                  $v.formData.email.$dirty &&
                                  (!$v.formData.email.required ||
                                    !$v.formData.email.email)
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="password"
                              >Password: <span>*</span></label
                            >
                            <input
                              v-model="formData.password"
                              type="password"
                              class="form-control"
                              placeholder="Password"
                              autocomplete="new-password"
                              :class="{
                                'input-border-errors':
                                  $v.formData.password.$dirty &&
                                  !$v.formData.password.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="mobile">Mobile:</label>
                            <input
                              v-model="formData.mobile"
                              type="tel"
                              class="form-control"
                              placeholder="Mobile"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="joining_date"
                              >Joining Date: <span>*</span></label
                            >
                            <datepicker
                              :minimumView="'day'"
                              :maximumView="'month'"
                              :initialView="'month'"
                              v-model="formData.joining_date"
                              :class="{
                                'input-border-errors':
                                  $v.formData.joining_date.$dirty &&
                                  !$v.formData.joining_date.required
                              }"
                            >
                              <span
                                slot="afterDateInput"
                                class="animated-placeholder"
                              >
                                <i class="far fa-calendar-alt"></i>
                              </span>
                            </datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="last_date">Last Date:</label>
                            <datepicker
                              :minimumView="'day'"
                              :maximumView="'month'"
                              :initialView="'month'"
                              v-model="formData.last_date"
                            >
                              <span
                                slot="afterDateInput"
                                class="animated-placeholder"
                              >
                                <i class="far fa-calendar-alt"></i>
                              </span>
                            </datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="gender" class="control-label"
                              >Select Gender<span>*</span></label
                            >
                            <Select2
                              :options="user_gender"
                              v-model="formData.gender"
                              :settings="{
                                placeholder: 'Select Gender',
                                allowClear: true
                              }"
                              :class="{
                                'input-border-errors':
                                  $v.formData.gender.$dirty &&
                                  !$v.formData.gender.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="job_title"
                              >Job Title: <span>*</span></label
                            >
                            <input
                              v-model="formData.job_title"
                              type="text"
                              class="form-control"
                              placeholder="Job Title"
                              :class="{
                                'input-border-errors':
                                  $v.formData.job_title.$dirty &&
                                  !$v.formData.job_title.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="hourly_rate"
                              >Hourly Rate: <span>*</span></label
                            >
                            <input
                              v-model="formData.hourly_rate"
                              type="number"
                              class="form-control"
                              placeholder="Hourly Rate"
                              :class="{
                                'input-border-errors':
                                  $v.formData.hourly_rate.$dirty &&
                                  (!$v.formData.hourly_rate.required ||
                                    !$v.formData.hourly_rate.numeric)
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div :class="classObject">
                        <div class="row">
                          <div class="form-group">
                            <label for="role_id" class="control-label"
                              >Select Role <span>*</span></label
                            >
                            <Select2
                              @change="resetValues($event)"
                              v-model="formData.role_id"
                              :options="roles_options"
                              :settings="{
                                placeholder: 'Select Role',
                                allowClear: true
                              }"
                              :class="{
                                'input-border-errors':
                                  $v.formData.role_id.$dirty &&
                                  !$v.formData.role_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div 
                        v-if="(selected_user_role == 'salesteamlead') || (selected_user_role == 'salesperson')" 
                        :class="classObject"
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="role_id" class="control-label"
                              >Select Manager</label
                            >
                            <Select2
                              @change="onSelectManager"
                              v-model="formData.manager_id"
                              :options="manager_options"
                              :settings="{
                                placeholder: 'Select Manager',
                                multiple : true,
                              }"
                            />
                          </div>
                        </div>
                      </div>

                      <div 
                        v-if="selected_user_role == 'salesperson'" 
                        :class="classObject"
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="role_id" class="control-label"
                              >Select Team Lead</label
                            >
                            <Select2
                              v-model="formData.salesteamlead_id"
                              :options="salesteamlead_options"
                              :settings="{
                                placeholder: 'Select Team Lead',
                                multiple : true,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div :class="classObject">
                        <div class="row">
                          <div
                            class="form-group"
                          >
                            <label for="brand_id" class="control-label"
                              >Select Brand <span>*</span>
                            </label>
                            <Select2
                              v-model="formData.brand_id"
                              :options="brand_options"
                              :settings="{
                                placeholder: 'Select Brand',
                                multiple: true
                              }"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_id.$dirty &&
                                  !$v.formData.brand_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div :class="classObjectSalesPerson">
                        <div class="row">
                          <div class="form-group">
                            <label for="status" class="control-label"
                              >User Status <span>*</span></label
                            >
                            <Select2
                              v-model="formData.status"
                              :options="user_status"
                              :settings="{ placeholder: 'User Status' }"
                            />
                          </div>
                        </div>
                      </div>
                      <div :class="classObjectSalesPerson">
                        <div class="row">
                          <div class="form-group">
                            <label for="login_status" class="control-label"
                              >Login Status<span>*</span></label
                            >
                            <Select2
                              v-model="formData.login_status"
                              :options="login_status"
                              :settings="{ placeholder: 'Login Status*' }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="address">Address:</label>
                            <textarea
                              v-model="formData.address"
                              class="form-control"
                              placeholder="Address"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-actions">
                            <button
                              :disabled="disableSubmitBtn"
                              type="submit"
                              id="save-form"
                              class="btn btn-primary default-global-btn"
                            >
                              Create
                            </button>
                            <div
                              v-if="disableSubmitBtn"
                              class="form_submit_loader"
                            >
                              <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";

const {
  required,
  minLength,
  email,
  numeric
} = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  components: {
    Select2,
    Datepicker
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    },
    classObject: function () {
      if( (this.selected_user_role == 'salesteamlead') || (this.selected_user_role == 'salesperson')) {
        return 'col-sm-4';
      }
      return 'col-sm-6';
    },
    classObjectSalesPerson: function () {
      if( this.selected_user_role == 'salesteamlead' ) {
        return 'col-sm-6';
      }
      if( this.selected_user_role == 'salesperson' ) {
        return 'col-sm-4';
      }
      return 'col-sm-6';
    }
  },
  data() {
    return {
      loader: true,
      formData: {
        name: null,
        email: null,
        password: null,
        mobile: null,
        joining_date: null,
        last_date: null,
        gender: null,
        job_title: "",
        hourly_rate: null,
        completion_percent: null,
        brand_id: null,
        role_id: null,
        status: "active",
        login_status: "enable",
        address: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // View Change
        manager_id : null,
        salesteamlead_id : null,
      },
      selected_user_role : null,
      user_gender: [
        {
          id: "male",
          text: "Male"
        },
        {
          id: "female",
          text: "Female"
        },
        {
          id: "others",
          text: "Others"
        }
      ],
      user_status: [
        {
          id: "active",
          text: "Active"
        },
        {
          id: "deactive",
          text: "Deactive"
        }
      ],
      login_status: [
        {
          id: "enable",
          text: "Enable"
        },
        {
          id: "disable",
          text: "Disable"
        }
      ],
      brand_options: null,
      roles_options: null,
      manager_options : null,
      salesteamlead_options : null,
      disableSubmitBtn: false,
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getprojectbrands();
    this.getRolesData();
    this.getManagerListData();
    this.loader = false;
  },
  methods: {
    async getprojectbrands() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-project-brand"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.brand_options = response.data.data;
        }
      });
    },
    async getRolesData() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-all-roles"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.roles_options = response.data.data;
        }
      });
    },
    async getManagerListData() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-managers-list"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.manager_options = response.data.data;
        }
      });
    },
    onSelectManager() {
      this.formData.salesteamlead_id = null;
      this.getTeamLeadData();
    },
    async getTeamLeadData() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-salesteamlead-list?manager_id=" + this.formData.manager_id
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.salesteamlead_options = response.data.data;
        }
      });
    },
    resetValues(event) {
      this.formData.brand_id = null;
      this.formData.manager_id = null;
      this.formData.salesteamlead_id = null;
      this.formData.selected_user_role = null;
      var valObj = this.roles_options.filter(function(elem){
          if( elem.id == parseInt(event) ) return elem;
      });
      this.selected_user_role = valObj[0].text.replace(/\s/g, '').toLowerCase();
    },
    async submit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();

      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }

      const data = $this.$store.dispatch("postHttp", {
        url: "employees/create",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          // console.log(response);
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: event.target
          });
          $this.$v.$reset();
          $this.formData.name = null;
          $this.formData.email = null;
          $this.formData.password = null;
          $this.formData.mobile = null;
          $this.formData.joining_date = null;
          $this.formData.last_date = null;
          $this.formData.gender = null;
          $this.formData.job_title = "";
          $this.formData.hourly_rate = null;
          $this.formData.completion_percent = null;
          $this.formData.brand_id = null;
          $this.formData.role_id = null;
          $this.formData.status = "active";
          $this.formData.login_status = "enable";
          $this.formData.address = "";
          $this.selected_user_role = null;
          $this.formData.manager_id = null;
          $this.formData.salesteamlead_id = null;
          $this.$router.push({ path: "/employees" });
        }
      });
      $this.disableSubmitBtn = false;
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  },
  validations: {
    formData: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required
      },
      joining_date: {
        required
      },
      gender: {
        required
      },
      job_title: {
        required
      },
      hourly_rate: {
        required,
        minValue: 0,
        numeric
      },
      brand_id: {
        required
      },
      role_id: {
        required
      }
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
body .form-area .form-group label span {
  color: red;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 100%;
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
      span.select2.select2-container.select2-container--default {
        width: 100% !important;
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.client-projects {
        padding-bottom: 0px;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: 231px;
          margin-bottom: 30px;
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 15px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
